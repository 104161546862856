<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="formType === 'ADD' ? 'Tambah Unit' : 'Ubah Unit'"
    class="px-5 py-3"
  >
    <page-loading :show="loading" />
    <form @submit.prevent="submit">
      <div class="form--group row">
        <label class="col-12 col-lg-3" for="priceField"
          >{{ $t('project.unitType.price') }}<span class="required">*</span></label
        >
        <div class="col-12 col-lg-6">
          <cleave
            v-model="price"
            :options="cleaveOptionCurrency"
            class="basic--input"
            name="price"
            type="text"
            placeholder="Min"
            id="priceField"
          />
          <span class="val-error">{{ validation.firstError('price') }}</span>
        </div>
      </div>
      <div
        v-show="unitBlockRule.allowed"
        class="form--group row"
        :class="{ 'has-error': validation.hasError('unitBlock') }"
      >
        <label class="col-12 col-lg-3" for="unitBlock"
          >{{ $t('project.unitType.block') }}
          <span v-show="unitBlockRule.required" class="required">*</span></label
        >
        <div class="col-12 col-lg-6">
          <v-text-field
            id="unitBlock"
            v-model="unitBlock"
            type="text"
            name="unitBlock"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('unitBlock') }}</span>
        </div>
      </div>
      <div class="form--group row" :class="{ 'has-error': validation.hasError('unitNumber') }">
        <label class="col-12 col-lg-3" for="unitNumber">{{
          $t('project.unitType.unitNumber')
        }}</label>
        <div class="col-12 col-lg-6">
          <v-text-field
            id="unitNumber"
            v-model="unitNumber"
            type="text"
            name="unitNumber"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('unitNumber') }}</span>
        </div>
      </div>
      <div
        class="form--group row"
        :class="{ 'has-error': validation.hasError('selectedUnitCategory') }"
      >
        <label class="col-12 col-lg-3" for="selectedUnitCategory"
          >{{ $t('project.unitType.category') }}<span class="required">*</span></label
        >
        <div class="col-12 col-lg-6">
          <multiselect
            name="selectedUnitCategory"
            id="selectedUnitCategory"
            v-model="selectedUnitCategory"
            :options="unitCategories"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
          />
          <span class="val-error">{{ validation.firstError('selectedUnitCategory') }}</span>
        </div>
      </div>
      <div
        class="form--group row"
        :class="{ 'has-error': validation.hasError('selectedUnitType') }"
      >
        <label class="col-12 col-lg-3" for="selectedUnitCategory"
          >{{ $t('project.unitType.unitType') }}<span class="required">*</span></label
        >
        <div class="col-12 col-lg-6">
          <multiselect
            name="selectedUnitType"
            id="selectedUnitType"
            v-model="selectedUnitType"
            :options="unitTypes"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
          />
          <span class="val-error">{{ validation.firstError('selectedUnitType') }}</span>
        </div>
      </div>
      <div
        class="form--group row"
        :class="{ 'has-error': validation.hasError('selectedBuildingDirection') }"
      >
        <label class="col-12 col-lg-3" for="selectedBuildingDirection">{{
          $t('project.unitType.buildingDirection')
        }}</label>
        <div class="col-12 col-lg-6">
          <multiselect
            name="selectedBuildingDirection"
            id="selectedBuildingDirection"
            v-model="selectedBuildingDirection"
            :options="buildingDirections"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
          />
          <span class="val-error">{{ validation.firstError('selectedBuildingDirection') }}</span>
        </div>
      </div>
      <div
        class="form--group row"
        v-show="allowFloor"
        :class="{ 'has-error': validation.hasError('selectedFloorType') }"
      >
        <label class="col-12 col-lg-3" for="selectedFloorType">{{
          $t('project.unitType.floorType')
        }}</label>
        <div class="col-12 col-lg-6">
          <multiselect
            name="selectedFloorType"
            id="selectedFloorType"
            v-model="selectedFloorType"
            :options="floorTypes"
            :searchable="true"
            :close-on-select="true"
            track-by="id"
            label="name"
          />
          <span class="val-error">{{ validation.firstError('selectedFloorType') }}</span>
        </div>
      </div>
      <div
        v-show="(selectedFloorType ? selectedFloorType.id == 3 : false) && allowFloor"
        class="form--group row"
        :class="{ 'has-error': validation.hasError('floor') }"
      >
        <label class="col-12 col-lg-3" for="floor">{{ $t('project.unitType.floorNumber') }}</label>
        <div class="col-12 col-lg-6">
          <v-text-field id="floor" v-model="floor" type="text" name="floor" outlined hide-details />
          <span class="val-error">{{ validation.firstError('floor') }}</span>
        </div>
      </div>
      <div
        class="form--group row"
        v-show="landSizeRule.allowed"
        :class="{ 'has-error': validation.hasError('landSize') }"
      >
        <label class="col-12 col-lg-3" for="landSize"
          >{{ $t('project.unitType.landSize')
          }}<span v-show="landSizeRule.required" class="required">*</span></label
        >
        <div class="col-12 col-lg-6">
          <v-text-field
            id="landSize"
            v-model="landSize"
            type="text"
            name="landSize"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('landSize') }}</span>
        </div>
      </div>
      <div
        v-show="buildingSizeRule.allowed"
        class="form--group row"
        :class="{ 'has-error': validation.hasError('buildingSize') }"
      >
        <label class="col-12 col-lg-3" for="buildingSize"
          >{{ $t('project.unitType.buildingSize')
          }}<span v-show="buildingSizeRule.required" class="required">*</span></label
        >
        <div class="col-12 col-lg-6">
          <v-text-field
            id="buildingSize"
            v-model="buildingSize"
            type="text"
            name="buildingSize"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('buildingSize') }}</span>
        </div>
      </div>
      <div
        v-show="bedroomRule.allowed"
        class="form--group row"
        :class="{ 'has-error': validation.hasError('bedroom') }"
      >
        <label class="col-12 col-lg-3" for="bedroom"
          >{{ $t('project.unitType.bedroom')
          }}<span v-show="bedroomRule.required" class="required">*</span></label
        >
        <div class="col-12 col-lg-6">
          <v-text-field
            id="bedroom"
            v-model="bedroom"
            type="text"
            name="bedroom"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('bedroom') }}</span>
        </div>
      </div>
      <div
        v-show="bathroomRule.allowed"
        class="form--group row"
        :class="{ 'has-error': validation.hasError('bathroom') }"
      >
        <label class="col-12 col-lg-3" for="bathroom"
          >{{ $t('project.unitType.bathroom')
          }}<span v-show="bathroomRule.required" class="required">*</span></label
        >
        <div class="col-12 col-lg-6">
          <v-text-field
            id="bathroom"
            v-model="bathroom"
            type="text"
            name="bathroom"
            outlined
            hide-details
          />
          <span class="val-error">{{ validation.firstError('bathroom') }}</span>
        </div>
      </div>
      <div class="form--group row">
        <label class="col-12 col-lg-3">Aktif?</label>
        <div class="col-12 col-lg-6">
          <v-btn
            type="button"
            color="success"
            :class="{ 'v-btn--disabled': !isActive }"
            @click="isActive = true"
          >
            {{ $t('general.yes') }}
          </v-btn>
          <v-btn
            type="button"
            color="error"
            :class="{ 'v-btn--disabled': isActive }"
            @click="isActive = false"
          >
            {{ $t('general.no') }}
          </v-btn>
        </div>
      </div>
      <div class="form--group row">
        <label class="col-12 col-lg-3">Sudah terjual?</label>
        <div class="col-12 col-lg-6">
          <v-btn
            type="button"
            color="success"
            :class="{ 'v-btn--disabled': !isSold }"
            @click="isSold = true"
          >
            {{ $t('general.yes') }}
          </v-btn>
          <v-btn
            type="button"
            color="error"
            :class="{ 'v-btn--disabled': isSold }"
            @click="isSold = false"
          >
            {{ $t('general.no') }}
          </v-btn>
        </div>
      </div>

      <div class="d-flex align-center justify-end">
        <v-btn color="primary" class="bottom--button" type="submit">
          {{ $t('project.btn.save') }}
        </v-btn>
      </div>
    </form>
  </base-material-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import Multiselect from 'vue-multiselect';
import validation from 'simple-vue-validator/src/validation-bag';
require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'unit-type-form',
  mixins: [HelperMixin],
  components: { PageLoading, Multiselect },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    ...mapState({
      formType: (state) => state.unit.form.formType,
      unitCategories: (state) => state.unit.form.unitCategories,
      unitTypes: (state) => state.unit.form.unitTypes,
      buildingDirections: (state) => state.unit.form.buildingDirections,
      floorTypes: (state) => state.unit.form.floorTypes,
      allowFloor: (state) => state.unit.form.allowFloor,
      allowLandSize: (state) => state.unit.form.allowLandSize,
      allowUnitBlock: (state) => state.unit.form.allowUnitBlock,
      cleaveOptionDecimal: (state) => state.global.cleaveOptionDecimal,
      cleaveOptionCurrency: (state) => state.global.cleaveOptionCurrency,
      cleaveOptionNumber: (state) => state.global.cleaveOptionNumber,
    }),
    ...mapGetters({
      bedroomRule: 'unit/form/bedroomRule',
      bathroomRule: 'unit/form/bathroomRule',
      landSizeRule: 'unit/form/landSizeRule',
      buildingSizeRule: 'unit/form/buildingSizeRule',
      unitBlockRule: 'unit/form/unitBlockRule',
    }),
    loading: {
      get() {
        return this.$store.state.unit.form.loading;
      },
      set(value) {
        this.$store.commit('unit/form/SET_LOADING', value);
      },
    },
    price: {
      get() {
        return this.$store.state.unit.form.price;
      },
      set(value) {
        this.$store.commit('unit/form/SET_PRICE', value);
      },
    },
    isActive: {
      get() {
        return this.$store.state.unit.form.isActive;
      },
      set(value) {
        this.$store.commit('unit/form/SET_IS_ACTIVE', value);
      },
    },
    isSold: {
      get() {
        return this.$store.state.unit.form.isSold;
      },
      set(value) {
        this.$store.commit('unit/form/SET_IS_SOLD', value);
      },
    },
    unitBlock: {
      get() {
        return this.$store.state.unit.form.unitBlock;
      },
      set(value) {
        this.$store.commit('unit/form/SET_UNIT_BLOCK', value);
      },
    },
    unitNumber: {
      get() {
        return this.$store.state.unit.form.unitNumber;
      },
      set(value) {
        this.$store.commit('unit/form/SET_UNIT_NUMBER', value);
      },
    },
    floor: {
      get() {
        return this.$store.state.unit.form.floor;
      },
      set(value) {
        this.$store.commit('unit/form/SET_FLOOR', value);
      },
    },
    landSize: {
      get() {
        return this.$store.state.unit.form.landSize;
      },
      set(value) {
        this.$store.commit('unit/form/SET_LAND_SIZE', value);
      },
    },
    buildingSize: {
      get() {
        return this.$store.state.unit.form.buildingSize;
      },
      set(value) {
        this.$store.commit('unit/form/SET_BUILDING_SIZE', value);
      },
    },
    bathroom: {
      get() {
        return this.$store.state.unit.form.bathroom;
      },
      set(value) {
        this.$store.commit('unit/form/SET_BATHROOM', value);
      },
    },
    bedroom: {
      get() {
        return this.$store.state.unit.form.bedroom;
      },
      set(value) {
        this.$store.commit('unit/form/SET_BEDROOM', value);
      },
    },
    selectedUnitCategory: {
      get() {
        return this.$store.state.unit.form.selectedUnitCategory;
      },
      set(value) {
        this.$store.commit('unit/form/SET_SELECTED_UNIT_CATEGORY', value);
      },
    },
    selectedUnitType: {
      get() {
        return this.$store.state.unit.form.selectedUnitType;
      },
      set(value) {
        this.$store.commit('unit/form/SET_SELECTED_UNIT_TYPE', value);
      },
    },
    selectedBuildingDirection: {
      get() {
        return this.$store.state.unit.form.selectedBuildingDirection;
      },
      set(value) {
        this.$store.commit('unit/form/SET_SELECTED_BUILDING_DIRECTION', value);
      },
    },
    selectedFloorType: {
      get() {
        return this.$store.state.unit.form.selectedFloorType;
      },
      set(value) {
        this.$store.commit('unit/form/SET_SELECTED_FLOOR_TYPE', value);
      },
    },
  },

  validators: {
    price(value) {
      return Validator.value(value).required(this.$i18n.t('errors.unit.price.required'));
    },
    unitNumber(value) {
      return Validator.value(value);
    },
    selectedUnitCategory(value) {
      return Validator.value(value).required(
        this.$i18n.t('errors.unit.selectedUnitCategory.required'),
      );
    },
    selectedUnitType(value) {
      return Validator.value(value).required(this.$i18n.t('errors.unit.selectedUnitType.required'));
    },
    landSize(value) {
      if (this.isActive && this.landSizeRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unit.landSize.required'));
      } else {
        return Validator.value(true);
      }
    },
    buildingSize(value) {
      if (this.isActive && this.buildingSizeRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unit.buildingSize.required'));
      } else {
        return Validator.value(true);
      }
    },
    bedroom(value) {
      if (this.isActive && this.bedroomRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unit.bedroom.required'));
      } else {
        return Validator.value(true);
      }
    },
    bathroom(value) {
      if (this.isActive && this.bathroomRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unit.bathroom.required'));
      } else {
        return Validator.value(true);
      }
    },
    unitBlock(value) {
      if (this.isActive && this.unitBlockRule.required) {
        return Validator.value(value).required(this.$i18n.t('errors.unit.unitBlock.required'));
      } else {
        return Validator.value(true);
      }
    },
  },
  methods: {
    async submit() {
      if (await this.$validate()) {
        await this.$store.dispatch('unit/form/postData');
        await this.$swal(
          this.$i18n.t('general.success'),
          this.$i18n.t('project.publish.addUnitMsg'),
          'success',
        );
        if (this.formType === 'EDIT') {
          this.$router.back();
        }
      } else {
        this.scrollToErrorSection(this.$el);
      }
    },
  },
};
</script>

<style scoped></style>
